<template>
    <div>
        <h2 class="mb-1">Notifications</h2>

        <b-row class="mt-2 mb-2">
            <b-col
                cols="12"
                md="12"
            >
                <h2>{{arrayNotifications.length.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results</span></h2>
            </b-col>

            <b-col md="12" xl="12">
                <b-table
                    :items="arrayNotifications"
                    :fields="fieldsTable"
                    responsive="sm"
                    hover
                    @row-clicked="onRowSelected"
                    :busy="isBusy"
                    striped
                    style="background: #fff"
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-1">Loading...</strong>
                        </div>
                    </template>

                    <template #cell(date)="data">
                        <div>
                            {{data.item.date}}
                            <b-badge
                                v-if="data.item.new"
                                pill
                                :variant="'primary'"
                                class="ml-50"
                            >
                                New
                            </b-badge>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer, BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,
            isClinician: '',
            fieldsTable: [
                { key: 'date', label: 'Date', sortable: false },
                { key: 'description', label: 'Description', sortable: false },
            ],
            arrayNotifications: [],

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',
            insuranceRates: '',
            didClientProvideCopay: '',
            didclientUtilizeSlidingScale: '',
            claimDateMonth: '',
            claimDateYear: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],
            counterInsuranceUtilizedForSession: 0,
            counterInsuranceReimbursementAmount: 0,
            counterclientsThatProvidedCopay: 0,
            counterAverageofCopay: 0,
            counterTotalSlidingScale: 0,
            counterAmountofSlidingScale: 0,
            counterClientDoesntHaveInsurance: 0,
            counterDeductibleNotMet: 0,
            counterDontAcceptClientsInsurance: 0,
            counterInsuranceDoesNotCoverTelehealthServices: 0,
            counterOther: 0,

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],
            optionsConnected: ['Yes', 'No'],
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            reasonWhyClientWasReferredToClinician: '',
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Cost of deductible',
                'Doesn’t want employer to find out',
                'Doesn’t want family member to find out',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],
            optionsClaimDateMonth: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            optionsClaimDateYear: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: true,
            firstLoadData: true,
            seenClients: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        BBadge,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        // ...mapGetters(['arrayFiltradoReportsClinician'])
    },
    watch: {
        // selectedRangeDate(val) {
        //     this.functionDateRange(val)
        // },
        // startDate(val) {
        //     // console.log(val)
        //     this.isBusy = true
        //     this.searchReportsClinicianStartDate(val)
        //     this.functionDateRange(this.selectedRangeDate)
        //     if(!val) {
        //         this.filterRestard()
        //     }
        //     this.rangeDinamic ? this.rangeDinamic = false : ''
        // },
        // endDate(val) {
        //     // console.log(val)
        //     this.isBusy = true
        //     this.searchReportsClinicianEndDate(val)
        //     if(!val) {
        //         this.filterRestard()
        //     }
        // },
        // clinicianName(val) {
        //     // console.log(val)
        //     if(val) {
        //         if(val.value === 'All Clinicians') {
        //             this.clinicianName = null
        //         } else {
        //             // console.log(val.uid)
        //             this.isBusy = true
        //             db.collection('clientsClaims').where('clinician', '==', val.uid).get()
        //             .then(engaged => {
        //                 // this.optionsClient = []
        //                 var dataClaim = []
        //                 engaged.forEach(clients => {
        //                     // var noRemoteSessions = clients.data().noRemoteSessions ? parseFloat(clients.data().noRemoteSessions) : ''
        //                     // var noPhoneSessions = clients.data().noPhoneSessions ? parseFloat(clients.data().noPhoneSessions) : ''
        //                     // var noVideoSessions = clients.data().noVideoSessions ? parseFloat(clients.data().noVideoSessions) : ''

        //                     dataClaim.push({
        //                         clinician: clients.data().clinician ? clients.data().clinician : '',
        //                         client: clients.data().client ? clients.data().client : '',
        //                         claimDateMonth: clients.data().claimDateMonth ? clients.data().claimDateMonth : '',
        //                         claimDateYear: clients.data().claimDateYear ? clients.data().claimDateYear : '',
        //                         clinicianProgram: clients.data().clinicianProgram ? clients.data().clinicianProgram : '',
        //                         clinicianID: clients.data().clinicianID ? clients.data().clinicianID : '',
        //                         clinicianName: clients.data().clinicianName ? clients.data().clinicianName : '',
        //                         regionClinician: clients.data().regionClinician ? clients.data().regionClinician : clients.data().region ? clients.data().region : '',
        //                         clientID: clients.data().clientID ? clients.data().clientID : '',
        //                         clientName: clients.data().clientName ? clients.data().clientName : '',
        //                         clientFormat: (clients.data().clientName ? clients.data().clientName : '') + '(' + (clients.data().clientID ? clients.data().clientID : '') + ')',
        //                         claimDate: clients.data().claimDateMonth ? '01/' + moment().month(clients.data().claimDateMonth).format('MM') + '/' + clients.data().claimDateYear : '',
        //                         claimDateFormat: clients.data().claimDateMonth ? moment().month(clients.data().claimDateMonth).format('MM') + '/' + clients.data().claimDateYear : '',
        //                         noIndividualSessions: clients.data().noIndividualSessions ? clients.data().noIndividualSessions : '0',
        //                         noGroupSessions: clients.data().noGroupSessions ? clients.data().noGroupSessions : '0',
        //                         noRemoteSessions: clients.data().noRemoteSessions ? clients.data().noRemoteSessions : '0',
        //                         noPhoneSessions: clients.data().noPhoneSessions ? clients.data().noPhoneSessions : '0',
        //                         noVideoSessions: clients.data().noVideoSessions ? clients.data().noVideoSessions : '0',
        //                         noAssessmentSessions: clients.data().noAssessmentSessions ? clients.data().noAssessmentSessions : '0',
        //                         noCoupleSessions: clients.data().noCoupleSessions ? clients.data().noCoupleSessions : '0',
        //                         noFamilySessions: clients.data().noFamilySessions ? clients.data().noFamilySessions : '0',
        //                         amountBilledToCFE: clients.data().amountBilledToCFE ? (clients.data().amountBilledToCFE.includes('$') ? clients.data().amountBilledToCFE : '$'+clients.data().amountBilledToCFE) : '$0',
        //                         totalamtpaid: '$0'
        //                     })
        //                 })
        //                 // console.log(dataClaim.length)
        //                 this.setSnapshotClientActivityReportsClinician(dataClaim)
        //             })
        //             this.searchReportsClinicianClinicianName(val.uid)
        //         }
        //     } else {
        //         this.filterRestard()
        //         this.searchReportsClinicianClinicianName('')
        //     }
        // },
        // clientName(val) {
        //     // console.log(val)
        //     if(val) {
        //         if(val.value === 'All Clients') {
        //             this.clientName = null
        //         } else {
        //             this.isBusy = true
        //             this.searchReportsClinicianClientName(val.uid)
        //         }
        //     } else {
        //         this.filterRestard()
        //         this.searchReportsClinicianClientName('')
        //     }
        // },
        // region(val) {
        //     // console.log(val)
        //     if(!val) {
        //         this.filterRestard()
        //     }
            
        //     this.searchReportsClinicianRegion(val)
        // },
        // clinicianBorough(val) {
        //     // console.log(val)
        //     if(!val) {
        //         this.filterRestard()
        //     }
            
        //     this.searchReportsClinicianRegion(val === 'Any Borough' ? null : val)
        // },
        // clinicianProgram(val) {
        //     // console.log(val)
        //     this.searchReportsClinicianProgram(val)
        // },
        // claimDateMonth(val) {
        //     this.searchReportsClinicianClaimDateMonth(val)
        // },
        // claimDateYear(val) {
        //     this.searchReportsClinicianClaimDateYear(val)
        // },
    },
    methods: {
        // ...mapActions(['setSnapshotClientActivityReportsClinician','searchReportsClinicianStartDate','searchReportsClinicianEndDate','searchReportsClinicianClinicianName','searchReportsClinicianClientName','searchReportsClinicianRegion','searchReportsClinicianProgram','searchReportsClinicianClaimDateMonth','searchReportsClinicianClaimDateYear']),
        checkReadyToExport() {
            if(this.json_data.length === 0) {
                this.readyToExport = false
            } else {
                this.readyToExport = true
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                    // console.log(formatDate1,expiredDate)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
            
        },
        onRowSelected(items) {
            // console.log(items.id)
            var user = auth.currentUser
            if(user && user.uid) {
                var filterReadNotification = this.arrayNotifications.filter(ele => ele.id === items.id)
                this.arrayNotifications.forEach(ele => {
                    if(ele.id === items.id) {
                        ele.new = filterReadNotification.length >= 1 ? false : true
                        ele.read = filterReadNotification.length >= 1 ? true : false
                    }
                })
                localStorage.setItem(`n_nyc${user.uid}`, JSON.stringify(this.arrayNotifications))
                this.$router.push({ name: 'notification-details', params: {id: items.id} })
            }
            // this.idClientClaim = items.id
            // this.statusEdit = true
            // console.log(this.idClientActivity)
        },
    },
    created() {
        this.firstLoadDOM = false
        this.firstLoadData = false
        this.readyToExport = true
        this.isBusy = true
        this.isClinician = ''

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            this.isClinician = ''
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }

            if(doc.data().role === 'clinician') {
                this.isClinician = doc.id
                this.clinicianName = {
                    uid: doc.id,
                    name: doc.data().firstName + ' ' + doc.data().lastName
                }
            }
        })

        if(user && user.uid) {
            this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clinicians/notifications')
            .then(res => {
                this.arrayNotifications = []
                this.isBusy = true
                var readNotifications = localStorage.getItem(`n_nyc${user.uid}`)
                var JSON_Notifications = []
                res.data.forEach(ele => {
                    if(readNotifications) {
                        JSON_Notifications = JSON.parse(readNotifications)
                    }

                    if(ele.expirationDateTrack && ele.statusReferral === 'active') {
                        var date = moment(ele.expirationDateTrack, 'MM-DD-YYYY').format('MM/DD/YYYY')
                        var dateFormat =  moment(date).format('x')
                        var dateConvert = parseInt(dateFormat, 10)

                        var day1 = new Date(); 
                        var day2 = new Date(dateConvert);
                        var difference= day2-day1;
                        var days = difference/(1000 * 3600 * 24);
                        
                        // only shows last 2 months
                        if(days >= -30 && days <= 30) {
                            var existNotificationRead = JSON_Notifications.filter(not => not.id === ele.id && not.read === true)
                            this.arrayNotifications.push({
                                id: ele.id,
                                date: moment(dateConvert).subtract(30, 'days').format("MM-DD-YYYY"),
                                // date: ele.expirationDateTrack,
                                description: ele.nombre + ', expires ' + ele.expirationDateTrack,
                                new: existNotificationRead.length ? false : true,
                                read: existNotificationRead.length ? true : false
                            })

                            this.arrayNotifications.sort(function(a,b) {
                                return new Date(a.date.split('-')) - new Date(b.date.split('-'));
                            })
                        }
                    }
                })
                localStorage.setItem(`n_nyc${user.uid}`, JSON.stringify(this.arrayNotifications))
                this.isBusy = false
            })
        }

        // add tracking details information for notification v2
        // db.collection('usuarios').get()
        // .then(docs => {
        //     docs.forEach(doc => {
        //         db.collection('usuarios').doc(doc.id).collection('details').doc('trackingDetail').get()
        //         .then((payload) => {
        //             if(payload.exists) {
        //                 if(payload.data().datePickers && payload.data().datePickers.length >= 1) {
        //                     var licenseExpirationDateTrack = null
        //                     var activeDateTrack = null
        //                     var expirationDateTrack = null
        //                     var malpracticeInsuranceExpirationDateTrack = null
        //                     malpracticeInsuranceExpirationDateTrack = payload.data().datePickers[5].value ? payload.data().datePickers[5].value : null
        //                     licenseExpirationDateTrack = payload.data().datePickers[7].value ? payload.data().datePickers[7].value : null
        //                     activeDateTrack = payload.data().datePickers[8].value ? payload.data().datePickers[8].value : null
        //                     expirationDateTrack = payload.data().datePickers[9].value ? payload.data().datePickers[9].value : null
                
        //                     db.collection('usuarios').doc(doc.id).update({
        //                         licenseExpirationDateTrack: licenseExpirationDateTrack,
        //                         activeDateTrack: activeDateTrack,
        //                         expirationDateTrack: expirationDateTrack,
        //                         malpracticeInsuranceExpirationDateTrack: malpracticeInsuranceExpirationDateTrack
        //                     })
        //                     .then(() => console.log('first'))
        //                 } else {
        //                     db.collection('usuarios').doc(doc.id).update({
        //                         licenseExpirationDateTrack: null,
        //                         activeDateTrack: null,
        //                         expirationDateTrack: null,
        //                         malpracticeInsuranceExpirationDateTrack: null
        //                     })
        //                     .then(() => console.log('second'))
        //                 }
        //             }
        //         })
        //     })
        // })
    }
}
</script>

<style>
.clinicianReportTable thead tr th {
    text-transform: none !important;
}
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>